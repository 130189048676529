<template>
  <v-card
    rounded="xl"
    outlined
    class="py-2"
  >
    <v-list-item>
      <ThumbnailUploader
        v-if="content.type === 'audio'"
        @setThumbnail="setThumbnail($event, idx)"
      />

      <v-list-item-avatar
        v-else
        size="80"
        rounded="xl"
        style="border: thin solid rgba(0, 0, 0, 0.12); cursor: grab;"
      >
        <v-img :src="getImgSrc(content)" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-text-field
          v-model="content.name"
          placeholder="Nome..."
          style="border-radius: 10px;"
          hide-details
          filled
          dense
          @change="setName"
        />
      </v-list-item-content>

      <v-list-item-action>
        <v-container>
          <v-row justify="center">
            <v-btn
              v-if="content.id"
              icon
              @click="optionsOn = !optionsOn"
            >
              <v-icon>
                {{ optionsOn ? mdiChevronUp : mdiChevronDown }}
              </v-icon>
            </v-btn>

            <v-btn
              v-if="update && !content.id"
              icon
              :disabled="!content.name"
              :loading="loading"
              @click="postContent"
            >
              <v-icon>
                {{ mdiCheck }}
              </v-icon>
            </v-btn>

            <v-btn
              v-if="update && !content.id"
              icon
              @click="$emit('removeContent')"
            >
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>

            <v-btn
              v-else
              icon
              :color="contextOn ? 'tertiary' : ''"
              @click="contextOn = !contextOn"
            >
              <v-icon>
                {{ mdiCompassRose }}
              </v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-list-item-action>
    </v-list-item>

    <v-expand-transition hide-on-leave>
      <v-container v-if="contextOn">
        <v-col>
          <v-textarea
            v-model="content.context"
            placeholder="Context..."
            style="border-radius: 15px;"
            hide-details
            filled
            dense
            @change="setContext"
          />
        </v-col>
      </v-container>
    </v-expand-transition>

    <v-expand-transition hide-on-leave>
      <div v-if="optionsOn">

        <v-divider class="mb-2" />

        <v-fade-transition hide-on-leave>
          <v-row
            v-if="!deleteDialog"
            justify="center"
          >
            <v-btn
              text
              @click="setState"
            >
              {{ content.state === 'ARCHIVED' ? 'reativar' : 'arquivar' }}
            </v-btn>

            <div class="mx-2" />

            <v-btn
              text
              color="danger"
              disabled
              @click="deleteItem"
            >
              deletar
            </v-btn>
          </v-row>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <v-row
            v-if="deleteDialog"
            justify="center"
          >
            <v-btn
              text
              @click="deleteDialog = false"
            >
              <v-icon>
                {{ mdiArrowLeft }}
              </v-icon>

              back
            </v-btn>

            <div class="mx-2"></div>

            <v-btn
              text
              color="danger"
            >
              are you sure you want to delete?
            </v-btn>
          </v-row>
        </v-fade-transition>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiCompassRose,
  mdiClose,
  mdiChevronDown,
  mdiChevronUp,
  mdiArrowLeft,
  mdiCheck
} from '@mdi/js'

import { mapGetters } from 'vuex'
import { ThumbnailUploader } from '@components/Image'
import API from '@api'

export default {
  components: {
    ThumbnailUploader
  },

  props: {
    update: {
      type: Boolean,
      default: false
    },
    bundle: {
      type: String,
      default: ''
    },
    content: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      mdiCompassRose,
      mdiClose,
      mdiChevronDown,
      mdiChevronUp,
      mdiArrowLeft,
      mdiCheck,

      loading: false,
      optionsOn: false,
      deleteDialog: false,
      contextOn: false,

      name: ''
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    content(newContent) {
      if (newContent.id) {
        this.loading = false
      }
    }
  },

  methods: {
    postContent() {
      this.loading = true
      this.$emit('postContent')
    },
    
    async setName() {
      if (this.update && this.content.id) {
        this.$emit('setLoading')

        await API().put(`/bundleContent/${this.user.id}`, {
          name: this.content.name
        },
        {
          params: {
            id: this.content.id
          }
        })

        this.$emit('resetLoading')
      }
    },

    async setContext() {
      if (this.update && this.content.id) {
        this.$emit('setLoading')

        await API().put(`/bundleContent/${this.user.id}`, {
          context: this.content.context
        },
        {
          params: {
            id: this.content.id
          }
        })

        this.$emit('resetLoading')
      }
    },

    setState() {
      if (this.content.state === 'ARCHIVED') {

        this.content.state = 'ACTIVE'

      } else {
        
        this.content.state = 'ARCHIVED'

      }

      API().put(`/bundleContent/${this.user.id}`, {
        state: this.content.state
      },
      {
        params: {
          id: this.content.id
        }
      })
    },

    deleteItem() {
      if (this.deleteDialog) {

        

      } else {

        this.deleteDialog = true

      }
    },

    getImgSrc(content) {

      if (content.id) {

        return content.type === 'image' ? content.url : content.thumbnail

      } else if (content.type === 'pdf' || content.type === 'video') {
      
        return content.thumbnailObj ? content.thumbnailObj.mediaSrc : ''
      
      } else if (content.type === 'image') {

        return content.fileObj ? content.fileObj.mediaSrc : ''

      } else if (content.type === 'youtube') {

        return content.thumbnail
      
      }   
    },

    setThumbnail(thumbnailObj, idx) {

      // if (idx > -1) {
  
      //   this.contentArray[idx].thumbnailObj = thumbnailObj
      
      // } else {

      //   this.thumbnailObj = thumbnailObj

      // }

    },
  }

}
</script>