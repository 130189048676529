<template>
  <v-dialog
    v-model="adminOn"
    max-width="600"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-card rounded="xl">
      <v-container class="py-0">
        <v-row justify="center">
          <v-col cols="11">
            <PrivacySettings @settings="setPrivacySettings" />
          </v-col>
        </v-row>

        <v-card-actions>
          <v-container class="py-1">
            <v-row justify="center">
              <v-btn
                large
                block
                elevation="0"
                style="border-radius: 12px"
              >
                {{ $t('save') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { PrivacySettings } from '@components'

export default {
  components: {
    PrivacySettings
  },

  props: {
    adminOn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>