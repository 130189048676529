<template>
  <v-dialog
    v-model="dialogOn"
    max-width="500"
    max-height="200"
    @click:outside="$emit('closeDialog')"
  >
    <input
      ref="imageInput"
      type="file"
      accept=".png,.jpg,.jpeg,.gif,.svg"
      @change="addThumbnail"
    />
    <ImageCropper
      stencilComponent="RectangleStencil"
      :imageFile="imageFile"
      :mimeType="mimeType"
      :cropperOn="cropperOn"
      @save="saveThumbnail"
      @closeDialog="cropperOn = false"
    />

    <v-card>
      <v-container>
        <v-row
          align="center"
          class="px-4"
        >
          <v-card-title>
            {{ room.id ? $t('edit_room') : $t('new_room') }}
          </v-card-title>

          <v-spacer />

          <v-btn
            icon
            @click="$emit('closeDialog')"
          >
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>

        <v-divider />

        <Scrollbar style="max-height: 300px;">
          <v-form ref="form" style="overflow-x: hidden;">
            <v-row justify="center" >
              <v-col
                cols="12"
                class="py-0 px-0"
              >
                <v-progress-linear
                  :active="loading"
                  indeterminate
                  color="primary"
                  absolute
                  top
                />

                <v-container class="py-0">
                  <v-row
                    justify="center"
                    align="center"
                    class="px-6"
                  >
                    <v-col cols="12">
                      <v-row>
                        <v-list-item
                          three-line
                          class="px-3"
                        >
                          <v-hover v-slot:default="{ hover }">
                            <v-list-item-avatar
                              rounded="lg"
                              size="106"
                              :color="avatarColor(hover)"
                              @click="clickImageInput"
                            >
                              <v-img :src="thumbnailObj.mediaSrc">
                                <HoverLayer v-if="hover || !thumbnailObj.mediaSrc">
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-icon
                                      dark
                                      large
                                    >
                                      {{ mdiImage }}
                                    </v-icon>
                                  </v-row>
                                </HoverLayer>
                              </v-img>
                            </v-list-item-avatar>
                          </v-hover>
                          
                          <v-list-item-content>
                            <v-container class="pt-1 pb-0">
                              <v-row justify="start">
                                <v-text-field
                                  v-model="name"
                                  dense
                                  outlined
                                  class="hs-rounded-lg"
                                  :label="$t('labels.name')"
                                  :rules="[v => !!v || $t('groups.newRules.name')]"
                                />
                              </v-row>
                              <v-row justify="start">
                                <v-text-field
                                  v-model="caption"
                                  dense
                                  outlined
                                  class="hs-rounded-lg"
                                  :label="$t('labels.caption')"
                                />
                              </v-row>
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                      </v-row>

                      <v-row>
                        <v-col class="py-0">
                          <v-textarea
                            v-model="about"
                            outlined
                            rows="3"
                            auto-grow
                            validate-on-blur
                            class="hs-rounded-lg"
                            :label="$t('labels.about')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-form>
        </Scrollbar>

        <v-card-actions>
          <v-btn
            block
            large
            elevation="0"
            style="border-radius: 12px;"
            :loading="loading"
            @click="submit"
          >
            {{ room.id ? $t('save') : $t('create_room') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageCropper from '@components/Image/Cropper'
import { MediaURL } from '@components'
import { mapGetters } from 'vuex'
import { HoverLayer } from '@components'
import { Scrollbar } from '@components/App'
import API from '@api'
import {
  mdiImage,
  mdiClose
} from '@mdi/js'

export default {
  components: {
    HoverLayer,
    ImageCropper,
    Scrollbar
  },

  props: {
    dialogOn: {
      type: Boolean,
      default: false
    },
    community: {
      type: String,
      default: ''
    },
    room: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiImage,
      mdiClose,

      loading: false,
      cropperOn: false,

      name: '',
      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },
      imageFile: {},
      
      mimeType: '',
      caption: '',
      about: '',
      tags: [],

      configs: [
        {
          title: 'public',
          text: 'groups.public',
          value: true
        },
        {
          title: 'unlisted',
          text: 'groups.unlisted',
          value: false
        },
        {
          title: 'invitationOnly',
          text: 'groups.invitationOnly',
          value: false
        }
      ]
    }
  },

  watch: {
    dialogOn() {
      if (this.room.id) {
        this.name = this.room.name
        this.thumbnailObj.mediaSrc = this.room.thumbnail
        this.caption = this.room.caption
        this.about = this.room.about
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myUsername: 'profile/username',
      myAvatar: 'profile/avatar',
      settings: 'settings'
    }),

    sender() {
      return {
        profile: this.myProfile,
        name: this.myName,
        username: this.myUsername,
        avatar: this.myAvatar
      }
    },
    
    btnColor() {
      return this.$vuetify.theme.dark
        ? 'white'
        : 'primary'
    },

    hoverLayerColor() {
      return this.$vuetify.theme.dark
        ? 'grey darken-4'
        : 'grey darken-2'
    }
  },

  methods: {
    clickImageInput() {
      this.$refs.imageInput.click()
    },

    async addThumbnail(e) {
      const [imageFile] = e.target.files;
      const mimeType = await this.getMimeType(imageFile);

      if (mimeType === 'image/gif') {
        this.thumbnailObj.mediaSrc = URL.createObjectURL(imageFile);
        this.thumbnailObj.filename = imageFile.name
        this.thumbnailObj.type = 'image/gif'
      } else {
        this.mimeType = mimeType;
        this.imageFile = imageFile;
        this.cropperOn = true;
      }
    },

    saveThumbnail(imageFile) {
      this.cropperOn = false

      this.thumbnailObj.mediaSrc = URL.createObjectURL(imageFile)
      this.thumbnailObj.filename = imageFile.name
      this.thumbnailObj.type = imageFile.type
    },

    avatarColor(hover) {
      return this.$vuetify.theme.dark
        ? hover
        ? 'grey darken-2'
        : 'grey darken-3'
        : hover
        ? 'grey'
        : 'grey lighten-2'
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        
        const roomData = {
          name: this.name,
          caption: this.caption,
          about: this.about
        }

        if (this.community) {
          roomData['community'] = this.community
        }

        try {
          if (this.thumbnailObj.mediaSrc && this.thumbnailObj.filename) {
            [roomData.thumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
          }

          let room;
          if (this.room.id) {
            room = await API().put(`room/${this.user.id}`, roomData, {
              params: {
                id: this.room.id
              }
            })

            this.$emit('update', room)

            this.clearAll()
            this.$emit('closeDialog')
          } else {
            room = await API().post(`room/${this.user.id}`, roomData)
            const member = await API().post(`member/${this.user.id}`, {
              room: room._id,
              profile: this.myProfile,
              type: 'SOCIAL',
              role: 'owner'
            })
            const conversation = await API().post(`conversation/${this.user.id}`, { // TODO: review
              room: room.id,
              name: room.name
            })
            
            conversation.messages = []
            room.conversations = [conversation]
            room.member = member

            this.$emit('setRoom', room)

            this.clearAll()
            this.$emit('closeDialog')
          }
        } catch(err) {
          this.$emit('err', 'errServer')
        } finally {
          this.loading = false
        }
      } else {
        this.$emit('err', 'incomplete')
      }
    },

    clearAll() {
      this.name = ''
      this.caption = ''
      this.about = ''
      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>