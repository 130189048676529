<template>
  <div>
    <Paywall
      :checkoutOn="checkoutOn"
      :subscription="subscription"
      @close="checkoutOn = false"
    />

    <!-- Hall Cover -->
    <v-row justify="center">
      <v-col style="position: relative">
        <Cover
          :cover="community.cover"
          :thumbnail="community.thumbnail"
        />
      </v-col>
    </v-row>

    <div style="height: 20px;"></div>

    <v-row justify="center">
      <v-col cols="10">
        <About :community="community" />

        <!-- Hall top level functions/control -->
        <Control
          :member="member"
          :reloadUserMember="loadUserMember"
          :members="members"
          :community="community"
          @setRoom="addRoom"
          @setProp="setProp"
          @answerInvitation="answerInvitation"
        />
      </v-col>

      <v-col cols="2">
        <CreatorCard />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      class="py-0 px-0"
    >
      <v-tab :ripple="false" class="font-weight-bold text-caption">Rooms</v-tab>
      <v-tab :ripple="false" class="font-weight-bold text-caption">
        <v-badge
          :color="member.role === 'owner' && memberRequestLength ? 'red' : 'transparent'"
        >
          <span
            v-if="member.role === 'owner' && memberRequestLength"
            slot="badge"
          >
            {{ memberRequestLength }}
          </span>
          Members
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <div class="my-2"></div>

    <RoomBar />

    <v-row justify="center">
      <v-col cols="3">
        <PersonaControl :notes="notes" />
      </v-col>

      <v-col cols="9" >
        <slot
          name="child"
          :members="members"
          :member="member"
          :community="community"
          :user="user"
          :activateMember="activateMember"
          :removeMember="removeMember"
        ></slot>
        <v-fade-transition hide-on-leave>
          <v-row
            v-if="tab === 1"
            justify="start"
          >
            <v-col
              cols="12"
              class="py-0"
            >
              <Members
                :membership="member"
                :members="members"
                :removeMember="removeMember"
                :activateMember="activateMember"
              />
            </v-col>
          </v-row>
        </v-fade-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Paywall from '@components/Hall/Paywall'
import Cover from '@components/Hall/Cover'
import Control from '@components/Hall/Control'
import Members from '@components/Hall/Members'
import About from '@components/Hall/About'
import RoomBar from '@components/Hall/RoomBar'
import { CreatorCard } from '@components'
import PersonaControl from '@components/Persona/PersonaControl'
import { v4 as uuidv4 } from 'uuid'
import API from '@api'

export default {
  props: {
    tab: Number,
    setTab: Function,
    _community: Object
  },

  components: {
    Paywall,
    Cover,
    Control,
    About,
    RoomBar,
    CreatorCard,
    PersonaControl,
    Members
  },

  async mounted() {
    this.loading = false  
    
    setTimeout(() => {
      this.notes = [{
        sender: this.anna,
        id: uuidv4(),
        title: 'Welcome',
        paragraphs: [`Welcome to ${this.community.name}! Here is your place to find communities to learn anything, meet new people and have lots of fun!`]
      }]
    }, 100)
  },

  async created() {
    if (!this.$route.path.includes('room') && this.$route.query.id) {
      this.community = await API().get(`community/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })
      this.loadUserMember()
    }
  },

  data() {
    return {
      newRoomOn: false,
      loading: true,
      checkoutOn: false,
      tabs: 0,
      subscription: null,
      rooms: [],
      members: [],
      community: {},
      member: {},
      notes: []
    }
  },

  computed: {
    ...mapGetters({
      anna: 'anna',
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    memberRequestLength() {
      return this.members.filter(member => member.status === 'requested').length
    },
  },

  watch: {
    tab: {
      handler(value) {
        this.setTab(value)
        if (value === 1 && this.community.id) this.loadMembers()
      }
    },
    _community: {
      handler(value) {
        this.community = value
        if (value) {
          if (!this.members.length) this.loadMembers()
          if (!this.member.id) this.loadUserMember()
        }
      }
    }
  },

  methods: {
    activateMember(memberId) {
      this.members.find(member => member._id === memberId).status = 'active'
    },
    removeMember(memberId) {
      this.members = this.members.filter(member => member._id !== memberId)
    },

    async loadUserMember() {
      this.member = await API().get(`member/community/${this.user.id}`, {
        params: {
          profile: this.profile,
          community: this.community.id
        }
      })
    },

    async loadMembers() {
      this.members = await API().get(`members/community/${this.user.id}`, {
        params: {
          community: this.community.id
        }
      })
    },

    setRooms(rooms) {
      this.rooms = rooms
    },

    answerInvitation({ accept }) {
      this.member.status = accept ? 'active' : 'request_declined'
      this.setMember({ status: this.member.status })
      if (!accept) this.$router.push('/halls')
    },

    async setMember(data) {
      await API().put(`member/${this.user.id}`, data, {
        params: {
          id: this.member.id
        }
      })

      for (let key in data) this.member[key] = data[key]
    },

    setProp({ prop, value, index, room }) {
      if (index > -1) {
        if (room) {
          const room = this.rooms[index]
          room[prop] = value
        }       
      } else {
        this.community[prop] = value
      }
    },

    addRoom(room) {
      this.rooms.push(room)
      this.newRoomOn = false
    }
  }
}
</script>
