<template>
  <v-dialog
    v-model="uploadOn"
    :max-width="700"
    @click:outside="$emit('close')"
  >
    <v-card
      rounded="xl"
      :height="600"
      style="position: relative;"
    >
      <v-container>
        <v-row justify="center">
          <v-col>
            <v-fade-transition hide-on-leave>
              <Scrollbar
                v-if="contentArray.length === 0"
                style="height: 550px;"
                :thin="true"
              >
                <v-container>
                  <FileInput @addMedia="addMedia" />

                  <v-row justify="center">
                    <div class="text-lighten-3">
                      OR
                    </div>
                  </v-row>

                  <v-container>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-textarea
                          v-model="youtubeLinks"
                          outlined
                          rows="2"
                          auto-grow
                          hide-details
                          style="border-radius: 20px"
                          label="YouTube Links"
                        />
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col cols="11">
                        <v-btn
                          text
                          block
                          @click="addYouTubeLink"
                        >
                          adicionar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </Scrollbar>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
              <div v-if="contentArray.length > 0">
                <Scrollbar
                  style="height: 490px;"
                  :thin="true"
                >
                  <v-container>
                    <v-row justify="center">   
                      <v-col
                        cols="12"
                        class="px-6"
                      >
                        <CoverUploader
                          :imgSrc="coverObj.mediaSrc"
                          @setProp="setProp"
                          @setCover="setCover($event)"
                        />
                              
                        <v-list-item
                          class="px-2"
                          style="transform: translateY(-50px); position: absolute; max-width: 100%;"
                        >
                          <ThumbnailUploader
                            :active="bundleOn"
                            @setThumbnail="setThumbnail($event, -1)"
                          />
                        </v-list-item>

                        <v-row
                          class="mt-16"
                          justify="start"
                          align="center"
                        >
                          <v-col
                            cols="8"
                            class="pr-0"
                          >
                            <v-text-field
                              v-model="bundleName"
                              placeholder="Bundle name"
                              style="border-radius: 10px;"
                              hide-details
                              :outlined="!$vuetify.theme.dark"
                              :filled="$vuetify.theme.dark"
                              dense
                            />
                          </v-col>

                          <v-col cols="4">
                            <v-row
                              class="px-4"
                              justify="end"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">
                                    <v-btn 
                                      large
                                      rounded
                                      icon
                                      depressed
                                      :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#E0E0E0' }`"
                                      :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
                                      @click="youtubeInput = !youtubeInput"
                                    >
                                      <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">
                                        {{ mdiYoutube }}
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>
                                  Adicionar links do YouTube
                                </span>
                              </v-tooltip>

                              <div class="mx-2" />
                              
                              <FileInputBtn @addMedia="addMedia" />

                              <div class="mx-2" />

                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">
                                    <v-btn 
                                      large
                                      rounded
                                      icon
                                      depressed
                                      :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#E0E0E0' }`"
                                      :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
                                      @click="resetContent"
                                    >
                                      <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">
                                        {{ mdiDelete }}
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>
                                  Limpar
                                </span>
                              </v-tooltip>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-expand-transition hide-on-leave>
                      <v-container v-if="youtubeInput">
                        <v-row justify="center">
                          <v-col cols="10">
                            <v-textarea
                              v-model="youtubeLinks"
                              outlined
                              rows="2"
                              auto-grow
                              hide-details
                              style="border-radius: 20px"
                              label="YouTube Links"
                            />
                          </v-col>
                        </v-row>

                        <v-row justify="center">
                          <v-col cols="10">
                            <v-btn
                              text
                              block
                              @click="addYouTubeLink"
                            >
                              adicionar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expand-transition>

                    <draggable
                      tag="div"
                      :list="contentArray"
                    >
                      <v-container
                        v-for="(content, idx) in contentArray"
                        :key="`content_${idx}`"
                      >
                        <UploaderItem :content="content" />
                      </v-container>
                    </draggable>                  

                    <v-progress-circular
                      v-if="loading"
                      class="ml-8 mt-2"
                      indeterminate
                      color="primary"
                    />        
                  </v-container>
                </Scrollbar>

                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col cols="10">
                    <v-btn
                      block
                      text
                      :loading="loading"
                      @click="submit"
                    >
                      save
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import FileInputBtn from '@components/File/FileInputBtn'
import FileInput from '@components/File/FileInput'
import UploaderItem from './UploaderItem'
import CoverUploader from '@components/Content/CoverUploader'
import { canvasToBlob } from '@utils'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { ThumbnailUploader } from '@components/Image'
import { MediaURL, PrivacySettings, ContentSettings } from '@components'
import API from '@api'

import draggable from 'vuedraggable';

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

import {
  mdiDelete,
  mdiClose,
  mdiChevronDown,
  mdiImage,
  mdiFilePdfBox,
  mdiMotionPlayOutline,
  mdiMicrophone,
  mdiCamera,
  mdiPackage,
  mdiYoutube
} from '@mdi/js'

export default {
  components: {
    FileInputBtn,
    FileInput,
    UploaderItem,
    CoverUploader,
    Scrollbar,
    ContentSettings,
    PrivacySettings,
    ThumbnailUploader,
    draggable
  },

  props: {
    community: {
      type: String,
      default: ''
    },
    uploadOn: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getVideoDuration = MediaURL.getVideoDuration.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiClose,
      mdiImage,
      mdiChevronDown,
      mdiCamera,
      mdiPackage,
      mdiDelete,
      mdiYoutube,

      youtubeInput: false,

      mediaArray: [],

      youtubeLinks: '',

      files: [],

      contentIcon: {
        'image': mdiImage,
        'pdf': mdiFilePdfBox,
        'video': mdiMotionPlayOutline,
        'audio': mdiMicrophone
      },

      bundleOn: true,
      bundleName: '',
      bundleCaption: '',

      showTitle: false,
        
      mimeType: '',
      imageFile: '',
      cropperOn: false,

      loading: true,

      editIdx: -1,
      contentArray: [],

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      coverObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      filename: '',
      type: '',
      videoSrc: '',

      title: '',
      author: '',
      description: '',
      tags: [],
      url: '',

      isAnonymous: false,
      isUnlisted: false,
      isPublic: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    disabled() {
      return !this.title || !this.author
    },

    btnColor() {
      return this.$vuetify.theme.dark
        ? 'white'
        : 'primary'
    },

  },

  watch: {
    mediaArray: {
      async handler(arr) {
        this.contentArray = this.contentArray.concat(arr)
      }
    },
    
    files: {
      async handler(fileArray) {
        this.loading = true
        
        let contentArray = []

        for (const file of fileArray) {
          const mimeType = await this.getMimeType(file)
          let fileObj, thumbnailObj;

          switch(mimeType) {
            case 'image/png':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/webp':

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: mimeType
              }
              
              contentArray.push({
                title: '',
                caption: '',
                fileObj,
                type: 'image'
              })

              break;

            case 'video/mp4':
            case 'video/webm':

              const [videoName] = file.name.split('.');

              thumbnailObj = {
                mediaSrc: await this.getVideoThumbnail(file),
                filename: `${videoName}.jpeg`,
                type: 'image/jpeg'
              }

              const mediaSrc = URL.createObjectURL(file)

              fileObj = {
                mediaSrc,
                filename: file.name,
                type: mimeType,
                duration: await this.getVideoDuration(mediaSrc)
              }

              contentArray.push({
                title: '',
                caption: '',
                thumbnailObj,
                fileObj,
                type: 'video'
              })

              break;

            case 'audio/mpeg':
            case 'audio/wav':
            case 'audio/ogg':

              thumbnailObj = {
                mediaSrc: '',
                filename: '',
                type: ''
              }

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: mimeType
              }
              
              contentArray.push({
                title: '',
                caption: '',
                fileObj,
                thumbnailObj,
                type: 'audio'
              })

              break;

            case 'application/pdf':

              thumbnailObj = await this.readPdfFile(file)

              fileObj = {
                mediaSrc: URL.createObjectURL(file),
                filename: file.name,
                type: 'application/pdf'
              }
              
              contentArray.push({
                title: '',
                caption: '',
                thumbnailObj,
                fileObj,
                type: 'pdf'
              })

              break;

            default:
              break;
          }
        }

        this.contentArray = contentArray
        this.loading = false

      },
      deep: true
    }
  },

  methods: {
    setProp({ prop, value }) {
      this.$set(this.$data, prop, value)
    },

    addMedia(mediaArray) {
      this.contentArray = this.contentArray.concat(mediaArray)
      this.loading = false
    },

    resetContent() {
      this.mediaArray = []
      this.contentArray = []
      this.emptyContent = true
    },

    async addYouTubeLink() {

      this.youtubeInput = false

      const shortYt = 'https://youtu.be/'

      const youtubeLinks = this.youtubeLinks.split('\n').filter(ytStr => ytStr)
      this.youtubeLinks = ''
      this.emptyContent = false

      const youtubeIds = youtubeLinks.map(youtubeUrl => {

        if (youtubeUrl.indexOf(shortYt) != -1) {

          return youtubeUrl.split(shortYt).pop()
        
        } else if (youtubeUrl.indexOf('watch?v=') != -1)  {
        
          return youtubeUrl.split('watch?v=').pop().split('&').shift()
        
        }

      })

      for (const youtubeId of youtubeIds) {
        const [{ snippet }] = await API().get('video/youtube', {
          params: { youtubeId }
        })

        this.contentArray.push({
          type: 'youtube',
          caption: '',
          youtubeId,
          name: snippet.localized.title,
          thumbnail: `https://i.ytimg.com/vi_webp/${youtubeId}/maxresdefault.webp`,
          url: shortYt + youtubeId
        })
      }

      this.$emit('setMedia', this.mediaArray)

      this.loading = false
    },

    async submit() {

      this.loading = true

      const bundleContents = []
      
      for (const content of this.contentArray) {

        switch(content.type) {
          case 'youtube':

            const youtubeObj = await API().post(`video/${this.user.id}`, {
              sender: this.sender,
              url: content.url,
              youtubeId: content.youtubeId,
              isYoutube: true,
              thumbnail: content.thumbnail,
              title: content.name,
              isLibraryContent: false
            })

            bundleContents.push({
              youtube: youtubeObj._id,
              url: content.url,
              name: content.name,
              context: content.context,
              thumbnail: content.thumbnail,
              type: 'youtube'
            })

            break;

          case 'pdf':

            const [pdfThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [pdfUrl] = await this.getMediaUrl([content.fileObj], 'pdfs')
          
            const pdfObj = await API().post(`pdf/${this.user.id}`, {
              url: pdfUrl,
              thumbnail: pdfThumbnail,
              sender: this.sender,
              title: content.name,
              description: content.description,
              isLibraryContent: false
            })

            bundleContents.push({
              pdf: pdfObj._id,
              url: pdfUrl,
              name: content.name,
              context: content.context,
              caption: content.caption,
              thumbnail: pdfThumbnail,
              type: 'pdf'
            })
            
            break;

          case 'video':

            const [videoThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [videoUrl] = await this.getMediaUrl([content.fileObj], 'videos')

            const videoObj = await API().post(`video/${this.user.id}`, {
              sender: this.sender,
              url: videoUrl,
              thumbnail: videoThumbnail,
              title: content.name,
              filename: content.fileObj.filename,
              caption: content.caption,
              duration: content.fileObj.duration,
              isLibraryContent: false
            })

            bundleContents.push({
              video: videoObj._id,
              url: videoUrl,
              name: content.name,
              context: content.context,
              caption: content.caption,
              thumbnail: videoThumbnail,
              type: 'video'
            })

            break;

          case 'image':

            const [imgUrl] = await this.getMediaUrl([content.fileObj], 'images')

            const imgObj = await API().post(`image/${this.user.id}`, {
              sender: this.sender,
              url: imgUrl,
              title: content.name,
              filename: content.fileObj.filename,
              caption: content.caption
            })

            bundleContents.push({
              image: imgObj._id,
              url: imgUrl,
              name: content.name,
              context: content.context,
              caption: content.caption,
              type: 'image'
            })

            break;

          case 'audio':

            const [audioThumbnail] = await this.getMediaUrl([content.thumbnailObj], 'images/thumbnails')
            const [audioUrl] = await this.getMediaUrl([content.fileObj], 'audios')

            const audioObj = await API().post(`audio/${this.user.id}`, {
              sender: this.sender,
              url: audioUrl,
              thumbnail: audioThumbnail,
              title: content.name,
              filename: content.fileObj.filename,
              caption: content.caption
            })

            bundleContents.push({
              audio: audioObj._id,
              url: audioUrl,
              name: content.name,
              context: content.context,
              thumbnail: audioThumbnail,
              caption: content.caption,
              type: 'audio'
            })

            break;

          default:
            break;
        }

      }

      if (this.bundleOn) {

        const [bundleThumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
        const [bundleCover] = await this.getMediaUrl([this.coverObj], 'images/covers')

        const bundleObj = await API().post(`bundle/${this.user.id}`, {
          community: this.community,
          sender: this.sender,
          name: this.bundleName,
          caption: this.bundleCaption,
          description: this.bundleDescription,
          thumbnail: bundleThumbnail,
          cover: bundleCover,
          showTitle: this.showTitle,
          index: this.index
        })

        this.$emit('newBundle', bundleObj)

        for (let i = 0; i < bundleContents.length; i++) {

          const bundleContent = bundleContents[i]
          const { type } = bundleContent

          await API().post(`bundleContent/${this.user.id}`, {
            [type]: bundleContent[type],
            bundle: bundleObj._id,
            sender: this.sender,
            name: bundleContent.name,
            caption: bundleContent.caption,
            url: bundleContent.url,
            thumbnail: bundleContent.thumbnail,
            type: bundleContent.type,
            index: i
          })

        }

      }

      this.loading = false

      this.resetContent()
      
    },

    setCover(coverObj) {
      this.coverObj = coverObj
    },

    setThumbnail(thumbnailObj, idx) {

      if (idx > -1) {
  
        this.contentArray[idx].thumbnailObj = thumbnailObj
      
      } else {

        this.thumbnailObj = thumbnailObj

      }

    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {

      this.isAnonymous = isAnonymous
      this.isUnlisted = isUnlisted
      this.isPublic = isPublic

    },

    async readPdfFile(pdf) {

      const pdfUrl = URL.createObjectURL(pdf)
      const pdfBlob = await fetch(pdfUrl).then(r => r.blob())
      const pdfBuffer = await pdfBlob.arrayBuffer()

      const pdfDoc = await pdfjsLib.getDocument(pdfBuffer).promise
      //this.numPages = this.pdfDoc.numPages

      return await this.createPDFThumbnail(pdf, pdfDoc, 1)

    },

    async createPDFThumbnail(pdfFile, pdfDoc, pageNum) {
      
      const page = await pdfDoc.getPage(Number(pageNum))
      const canvas = document.createElement('canvas')
      const viewport = page.getViewport({ scale: 1.0 })
      const context = canvas.getContext('2d')
      
      canvas.height = viewport.height
      canvas.width = viewport.width
      
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
  
      await page.render(renderContext).promise

      const blobData = await canvasToBlob(canvas);

      const [pdfName] = pdfFile.name.split('.');

      const thumbnailFile = new File([blobData], `${pdfName}.jpeg`, {
        type: 'image/jpeg'
      });

      const thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }

      thumbnailObj.mediaSrc = URL.createObjectURL(thumbnailFile)
      thumbnailObj.filename = `${pdfName}.jpeg`
      thumbnailObj.type = blobData.type

      return thumbnailObj

    }
    
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>  