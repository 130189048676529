<template>
  <v-card
    :outlined="!$vuetify.theme.dark"
    elevation="0"
    :height="`${$vuetify.breakpoint.xl ? 83 : 80}vh`"
    class="hs-rounded-xl"
    style="position: sticky; top: 30px;"
  >
    <v-container>
      <v-btn
        block
        elevation="0"
        x-large
        style="border-radius: 12px;"
      >
        {{ $t('call_anna')  }}
      </v-btn>
    </v-container>

    <Note
      v-for="(note, index) in notes"
      :key="index"
      :index="index"
      :note="note"
      :noteId="note.id"
    />
    
    <v-container class="bottom-menu">
      <v-btn
        large
        elevation="0"
        style="border-radius: 12px !important; width: 100%;"
      >
        create a room
      </v-btn>
    </v-container>
  </v-card>
</template>

<script>
import { Note } from '@components/Note'

export default {
  components: {
    Note
  },
  
  props: {
    notes: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>