<template>
  <div class="d-flex">
    <v-btn
      text
      color="primary"
      :loading="accepting"
      :disabled="accepting || declining"
      @click="onAccept"
    >
      {{ $t('accept') }}
      <v-icon>
        {{ mdiCheckboxMarkedOutline }}
      </v-icon>
    </v-btn>
    
    <v-btn
      text
      color="red darken-4"
      :loading="declining"
      :disabled="declining || accepting"
      @click="onDecline"
    >
      {{ $t('decline') }}
      <v-icon>
        {{ mdiClose }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import Member from '@api/member'
  import { mapGetters } from 'vuex'

  import {
    mdiCheckboxMarkedOutline,
    mdiClose
  } from '@mdi/js'

  export default {
    props: {
      memberId: String,
      members: Array,
      removeMember: Function,
      activateMember: Function
    },

    data: () => ({
      mdiCheckboxMarkedOutline,
      mdiClose,
      memberService: null,
      accepting: false,
      declining: false
    }),

    created() {
      this.memberService = Member(this.user.id)  
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
    },

    methods: {
      async onAccept() {
        this.accepting = true
        await this.memberService.acceptCommunityRequest({memberId: this.memberId})
        this.accepting = false
        this.activateMember(this.memberId)
      },
      async onDecline() {
        this.declining = true
        await this.memberService.declineCommunityRequest({memberId: this.memberId})
        this.declining = false
        this.removeMember(this.memberId)
      }
    }
  }
</script>