<template>
  <v-dialog
    v-model="updateOn"
    :max-width="700"
    @click:outside="$emit('close')"
  >
    <v-card
      rounded="xl"
      height="620"
    >
      <v-container>
        <v-row justify="center">
          <v-col>
            <Scrollbar
              style="height: 510px;"
              :scrollUpdateOn="false"
              :thin="true"
            >
              <v-container>
                <v-row justify="center">   
                  <v-col
                    cols="12"
                    class="px-6"
                  >
                    <CoverUploader
                      :settingsOn="false"
                      :imgSrc="cover"
                      @setCover="setCover"
                    />
                          
                    <v-list-item
                      class="px-2"
                      style="transform: translateY(-50px); position: absolute; max-width: 100%;"
                    >
                      <ThumbnailUploader
                        :active="bundleOn"
                        :imgSrc="thumbnail"
                        @setThumbnail="setThumbnail"
                      />
                    </v-list-item>

                    <v-row
                      class="mt-16"
                      justify="start"
                      align="center"
                    >
                      <v-col cols="12">
                        <v-text-field
                          v-model="name"
                          placeholder="Nome do ambiente"
                          style="border-radius: 10px;"
                          dense
                          :outlined="!$vuetify.theme.dark"
                          :filled="$vuetify.theme.dark"
                        />

                        <v-textarea
                          v-model="about"
                          outlined
                          auto-grow
                          hide-details
                          style="border-radius: 15px"
                          label="About"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>    
              </v-container>
            </Scrollbar>
          </v-col>
        </v-row>

        <v-row justify="center" class="px-6">
        
            <v-btn
              block
              x-large
              style="border-radius: 12px"
              elevation="0"
              :loading="loading"
              @click="submit"
            >
              save
            </v-btn>
      
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@api'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { ThumbnailUploader } from '@components/Image'
import { MediaURL } from '@components'
import FileInputBtn from '@components/File/FileInputBtn'
import CoverUploader from '@components/Content/CoverUploader'
import FileInput from '@components/File/FileInput'
import {
  mdiDotsHorizontal,
  mdiDotsHorizontalCircleOutline,
  mdiDelete,
  mdiClose,
  mdiChevronDown,
  mdiImage,
  mdiCamera,
  mdiCheck
} from '@mdi/js'

export default {
  components: {
    FileInputBtn,
    CoverUploader,
    FileInput,
    Scrollbar,
    ThumbnailUploader
  },

  props: {
    community: {
      type: Object,
      default() {
        return {}
      }
    },
    updateOn: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiClose,
      mdiImage,
      mdiChevronDown,
      mdiCamera,
      mdiDelete,
      mdiDotsHorizontal,
      mdiDotsHorizontalCircleOutline,
      mdiCheck,

      loading: false,

      name: '',
      about: '',

      thumbnail: '',
      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      cover: '',
      coverObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  },

  watch: {
    community: {
      handler: function() {
        this.resetData()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    }
  },

  methods: {
    async submit() {
      this.loading = true

      if (this.thumbnailObj.mediaSrc) {
        const [thumbnailUrl] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')
        this.setProp({ prop: 'thumbnail', value: thumbnailUrl })
      }


      if (this.coverObj.mediaSrc) {
        const [coverUrl] = await this.getMediaUrl([this.coverObj], 'images/covers')
        this.setProp({ prop: 'cover', value: coverUrl })
      }

      if (this.name != this.community.name) {
        this.setProp({ prop: 'name', value: this.name })
      }

      if (this.about != this.community.about) {
        this.setProp({ prop: 'about', value: this.about })
      }

      setTimeout(() => {
        this.resetData()
        this.loading = false
        this.$emit('close')
      }, 100)
    },

    setProp({ prop, value }) {
      this.update(value, prop)
      this.$emit('setProp', { prop, value, index: -1 })
    },

    async update(value, prop) {
      API().put(`community/${this.user.id}`, {
        [prop]: value
      }, {
        params: {
          id: this.community.id
        }
      })
    },

    setCover(coverObj) {
      this.coverObj = coverObj
    },

    setThumbnail(thumbnailObj) {
      this.thumbnailObj = thumbnailObj
    },

    resetData() {
      this.coverObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      
      this.name = this.community.name
      this.about = this.community.about
      this.thumbnail = this.community.thumbnail
      this.cover = this.community.cover
    }
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>  