<template>
  <div>
    <v-card
      outlined
      rounded="xl"
    >
      <v-img
        :aspect-ratio="3.5"
        height="300"
        :src="getResourceUrl(cover)"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader
            width="100%"
            height="100%"
            type="image"
          />
        </template>
      </v-img>
    </v-card>

    <v-container style="transform: translateY(-130px); position: absolute; max-width: 100%;">
      <v-list-item>
        <v-list-item-avatar
          size="150"
          rounded="xl"
          :style="`border-color: ${$vuetify.theme.dark ? '#212121' : '#FAFAFA'};
            border-radius: 20px; border-style: solid;`"
        >
          <v-img :src="getResourceUrl(thumbnail)">
            <template v-slot:placeholder>
              <v-skeleton-loader
                width="100%"
                height="100%"
                type="image"
              />
            </template>
          </v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-container>
  </div>
</template>

<script>
import { getResourceUrl } from '@utils'

export default {
  props: {
    cover: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: String,
      default: ''
    }
  },

  methods: {
    getResourceUrl
  }
}
</script>
