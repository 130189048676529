<template>
  <div>
    <v-select
      v-if="showItems"
      v-model="inputValue"
      :items="items"
      item-text="text"
      item-value="id"
      v-on:change="onChange"
      :rules="rules"
      filled
      dense
      class="hs-rounded-12"
      v-bind="$attrs"
    />
    <v-chip
      v-else
      color="primary"
      text-color="white"
      style="border-radius: 30px;"
    >
      {{ $t(`community.permissions.${value}`) }}
    </v-chip>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      onChange: Function,
      value: String,
      rules: Array,
      member: Object,
      userMember: Object
    },

    computed: {
      ...mapGetters({
        profileId: 'profile/id'
      }),

      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      
      showItems() {
        if (!this.userMember) return false;
        if (this.userMember._id === this.member._id) return false;
        return this.rolesAllowedToEdit[this.userMember.role].includes(this.value);
      }
    },

    data() {
      return {
        items: [
          ...(
            (this.value === 'owner' || this.userMember.role === 'owner') ? [
              {
                id: 'owner',
                text: this.$t('community.permissions.owner'),
              }
            ] : []
          ),
          {
            id: 'admin',
            text: this.$t('community.permissions.admin'),
            ...(['moderator', 'member'].includes(this.userMember.role) && { disabled: true })
          },
          {
            id: 'moderator',
            text: this.$t('community.permissions.moderator')
          },
          {
            id: 'member',
            text: this.$t('community.permissions.member')
          }
        ],
        rolesAllowedToEdit: {
          owner: ['owner', 'admin', 'moderator', 'member'],
          admin: ['admin', 'moderator', 'member'],
          moderator: ['moderator', 'member'],
          member: []
        }
      } 
    }
  }
</script>