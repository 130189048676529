<template>
  <v-row
    align="center"
    justify="center"
    class="py-2 px-4"
  >
    <div :class="{ 'col-20': $vuetify.breakpoint.xl, 'col-22': $vuetify.breakpoint.lg }">
      <v-btn
        elevation="0"
        style="border-radius: 10px !important;"
        @click="$router.go(-1)"
      >
        <v-icon>
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>

      <span class="mx-2"></span>

      <v-btn
        elevation="0"
        class="mr-5"
        :color="$vuetify.theme.dark ? 'primary' : 'tertiary'"
        style="border-radius: 10px !important; flex-grow: 1;"
      >
        AI Personas
      </v-btn>
    </div>

    <v-text-field
      filled
      rounded
      dense
      hide-details
      class="mx-4"
      style="border-radius: 12px"
      placeholder="Search content"
      :prepend-inner-icon="mdiMagnify"
    />

    <v-sheet
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
      style="border-radius: 12px; display: flex; justify-content: center; align-items: center; height: 40px; transition-duration: 0.5s;"
      class="switch-hall-style"
      width="90"
    >
      <v-btn-toggle
        :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-3'"
        mandatory
        group
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
            >
              <v-icon>
                {{ mdiCompassOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Explore Halls</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :color="`grey ${$vuetify.theme.dark ? 'darken' : 'lighten'}-1`"
            >
              <v-icon>
                {{ mdiFolderOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Folders: coming soon</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-sheet>
  </v-row>
</template>

<script>
import {
  mdiMagnify,
  mdiFolderOutline,
  mdiCompassOutline,
  mdiArrowLeft
} from '@mdi/js';

export default {
  data: () => ({
    mdiMagnify,
    mdiFolderOutline,
    mdiCompassOutline,
    mdiArrowLeft,

    live: false
  })
}
</script>

<style scoped>
/* Remove top margin/padding from selection controls */
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
} 

/* Style button group */
.v-item-group .v-btn {
  min-width: 30px !important;
  min-height: 30px !important;
  border-radius: 6px !important;
}

/* Style toggle buttons */
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 32px;
  width: 35px; 
}

/* Reduce content padding on buttons */
.v-btn__content {
  padding: 2px !important;
}

/* Style icons in buttons */
.v-btn .v-icon {
  font-size: 16px !important;
}

/* Round hall style buttons */ 
.switch-hall-style .v-btn--round {
  border-radius: 30% !important;
}

/* Position status indicator dot */
.status-indicator {
  top: 10px;
  right: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

/* Style offline status dot */
.offline {
  background-color: #ccc; 
}

/* Style live indicator dot in light theme */
.theme--light .live-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #02ae02;
  box-shadow: 0 0 0 0 rgba(1, 186, 1, 0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

/* Style live indicator dot in dark theme */
.theme--dark .live-indicator {
  display: inline-block; 
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #00ff00;
  box-shadow: 0 0 0 0 rgba(0,255,0,0.7);
  animation: pulse 1.8s ease-in-out infinite;
}

/* Pulse animation keyframes */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.487);
  }
  
  33% {
    box-shadow: 0 0 0 6px rgba(0,255,0,0);
  }
  
  66% {
    box-shadow: 0 0 0 8px rgba(0,255,0,0);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(0,255,0,0);
  }
}

.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  align-items: center;
}

.col-22 {
  flex: 0 0 22%;
  max-width: 22%;
  display: flex;
  align-items: center;
}
</style>