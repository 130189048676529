<template>
  <v-dialog
    v-model="checkoutOn"
    :max-width="$vuetify.breakpoint.xl ? 900 : 700"
    scrollable
    @click:outside="$emit('close')"
  >
    <v-form ref="form">
      <v-card rounded="xl">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <CheckOut
                :subscription="subscription"
                @newSubscription="emitEvent('newSubscription', $event)"
                @newMember="emitEvent('newMember', $event)"
              />
            </v-col>

            <v-col cols="4">
              <v-sheet
                class="py-2"
                :color="sheetColor"
                rounded="xl"
                height="100%"
                width="100%"
              >
                <v-list-item>
                  <v-list-item-avatar
                    size="70"
                    rounded="xl"
                  >
                    <v-img src="https://hisolverlab.s3.amazonaws.com/images/292108530_598397031910689_3854908716991734244_n.jpg" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      Iuvenis
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      Mentoria Premium
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :class="`font-weight-medium grey--text text--${ $vuetify.theme.dark ? 'lighten-2' : 'darken-3' }`"
                >
                  <v-icon
                    :color="subscriptionColorMap"
                    class="mr-2"
                  >
                    {{ mdiRadioboxMarked }}
                  </v-icon>

                  {{ !subscription ? 'Não assinado' : subscription.status === 'active' ? 'Assinatura ativa' : 'Pagamento pendente' }}
                </v-list-item>

                <v-divider
                  inset
                  class="mb-2"
                />

                <v-list-item>
                  <div>
                    • Acesso à plataforma com aulas técnicas gravadas
                  </div>
                </v-list-item>

                <div class="py-1" />

                <v-list-item>
                  <div>
                    • Plantões de dúvidas semanais ao vivo
                  </div>
                </v-list-item>

                <div class="py-1" />

                <v-list-item>
                  <div>
                    • Acesso à comunidade interna da iuvenis
                  </div>
                </v-list-item>

                <div class="py-1" />

                <v-list-item>
                  <div>
                    • Divulgação de oportunidades, networking, terapia em grupo e muito mais
                  </div>
                </v-list-item>

                <div class="py-1" />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>    
  </v-dialog>
</template>

<script>
import CheckOut from '@components/Wallet/CheckOut'

export default {
  components: {
    CheckOut
  },

  props: {
    checkoutOn: {
      type: Boolean,
      default: false
    },
    subscription: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  methods: {
    emitEvent(eventName, $event) {
      this.$emit(eventName, $event)
    }
  }
}
</script>
