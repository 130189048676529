<template>
  <div>
    <v-container>
      <v-badge
        inline
        avatar
        color="transparent"
      >
        <template v-slot:badge>
          <v-avatar>
            <v-img
              contain
              height="24"
              width="24"
              src="@assets/svgs/checkmark.svg"
            >
            </v-img>
          </v-avatar>
        </template>

        <div :class="`grey--text ${ $vuetify.theme.dark ? 'text--lighten-3' : 'text--darken-1' } text-h4`">
          {{ community.name }}
        </div>
      </v-badge>
    </v-container>
    
    <v-container>
      <div 
        :class="`grey--text ${ $vuetify.theme.dark ? 'text--lighten-1' : 'text--darken-3' }`"
        :style="`
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          max-height: ${showMore ? '15em' : '4.5em'};
          transition: max-height .5s linear;
        `"
      >
        {{ community.about }}
      </div>
      <!-- 'About' sections shorter than 230 chars shouldn't overflow -->
      <span
        v-if="community.about && community.about.length > 230"
        :class="{ 'clickable font-weight-bold grey--text': true, 'text--lighten-4': $vuetify.theme.dark, 'text--darken-4': !$vuetify.theme.dark }"
        @click="showMore = !showMore"
      >
        {{ showMore ? 'Show less' : 'Show more' }}
      </span>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    community: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    showMore: false
  })
}
</script>