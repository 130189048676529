<template>
  <v-card
    elevation="0"
    rounded="xl"
  >
    <v-container>
      <v-row class="px-3">
        {{_capitalize($t('groups.members'))}}
      </v-row>
      <v-spacer />
      <v-row
        class="px-3 py-1"
      >
        <v-col
          v-for="member in members"
          cols="6"
        >
          <v-card
            class="px-3 py-1"
            rounded="xl"
            :style="`display: flex; align-items: center; cursor: pointer; background-color: ${memberCardBackgroundColor};`"
            outlined
          >
            <ProfileAvatar
              :avatar="member.profile.avatar"
            /> 
            <div class="member-controllers-container">
              <div>
                {{ member.profile.name }}  
              </div>

              <div
                v-if="!!membership"
                class="d-flex"
              >
                <MemberRequestButtons
                  v-if="['owner', 'admin'].includes(membership.role) && member.status === 'requested'"
                  :memberId="member._id"
                  :members="members"
                  :removeMember="removeMember"
                  :activateMember="activateMember"
                />
                <div>
                  <SelectCommunityPermission
                    v-model="member.role"
                    style="max-width: 150px;"
                    :onChange="(value) => onPermissionChange(value, member._id)"
                    :userMember="membership"
                    :member="member"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import ProfileAvatar from '@components/Hall/ProfileAvatar'
  import SelectCommunityPermission from '@components/Input/SelectCommunityPermission'
  import MemberRequestButtons from '@components/Hall/MemberRequestButtons'
  import Member from '@api/member'
  import { mapGetters } from 'vuex'
  import { capitalize } from 'lodash'

  export default {
    props: {
      members: Array,
      membership: Object,
      removeMember: Function,
      activateMember: Function
    },

    components: {
      ProfileAvatar,
      SelectCommunityPermission,
      MemberRequestButtons
    },

    data() {
      return {
        memberService: null
      }
    },

    created() {
      console.log('removeMember: ', this.removeMember);
      this.memberService = Member(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
      memberCardBackgroundColor() {
        return this.$vuetify.theme.dark ? '#121212' : '#f5f5f5'
      },
    },

    methods: {
      _capitalize: capitalize,
      async onPermissionChange(role, memberId) {
        return this.memberService.update(memberId, { role })
      },
    }
  }
</script>

<style>
  .member-controllers-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .v-input__control {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    margin: auto !important;
  }
</style>