<template>
  <div>
    <InvitationDialog
      :inviteOn="inviteOn"
      :members="members"
      :community="community"
      @close="inviteOn = false"
      @newInvitees="$emit('newInvitees', $event)"
      @snack="emitSnack"
    />

    <NewRoom
      :dialogOn="newRoomOn"
      :community="community.id"
      :room="room"
      @closeDialog="newRoomOn = false"
      @setRoom="$emit('setRoom', $event)"
      @update="$emit('updateRoom', $event)"
    />

    <HallUpdater
      :community="community"
      :updateOn="editHall"
      @close="editHall = false"
      @setProp="$emit('setProp', $event)"
    />

    <ContentUploader
      :community="community.id"
      :uploadOn="uploadOn"
      :files="files"
      @close="closeUpload"
      @newBundle="addBundle"
    />

    <Admin
      :adminOn="adminOn" 
      @close="adminOn = false"
    />

    <v-container v-if="member.role === 'owner'">
      <v-row
        justify="start"
        class="px-3"
      >
        <v-btn 
          large
          rounded
          depressed
          :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
          @click="inviteOn = !inviteOn"
        >
          {{ $t('invite_members') }}
        </v-btn>

        <div class="mx-2"></div>

        <v-btn 
          large
          rounded
          depressed
          :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
          @click="newRoomOn = true"
        >
          {{ $route.name === 'community.room' ? $t('edit_room') : $t('new_room') }}
        </v-btn>

        <div class="mx-2"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <!-- TODO: abstract the rounded style -->
              <!-- See the PersonaPage -->
              <v-btn
                large
                rounded
                icon
                depressed
                :style="`background-color: ${ $vuetify.theme.dark ? '#212121' : '#E0E0E0' }`"
                @click="editHall = !editHall"
              >
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Edit community
          </span>
        </v-tooltip>

        <div class="mx-2"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                large
                rounded
                icon
                depressed
                :style="`background-color: ${ $vuetify.theme.dark ? '#212121' : '#E0E0E0' }`"
                @click="uploadOn = true"
              >
                <v-icon>{{ mdiUpload }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Upload content
          </span>
        </v-tooltip>

        <div class="mx-2"></div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                large
                rounded
                icon
                depressed
                :style="`background-color: ${ $vuetify.theme.dark ? '#212121' : '#E0E0E0' }`"
                @click="adminOn = !adminOn"
              >
                <v-icon>{{ mdiShieldCrownOutline }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Admin
          </span>
        </v-tooltip>
      </v-row>
    </v-container>

    <v-fade-transition hide-on-leave>
      <v-container v-if="member.role === 'member' && member.status === 'invited'">
        <v-row
          align="center"
          justify="start"
          class="px-3"
        >
          <v-btn 
            large
            rounded
            depressed
            outlined
            color="success"
            @click="$emit('answerInvitation', { accept: true })"
          >
            accept invitation
            <v-icon right>
              {{ mdiCheck }}
            </v-icon>
          </v-btn>

          <div class="mx-1"></div>
          
          <v-btn 
            large
            rounded
            outlined
            depressed
            color="danger"
            @click="$emit('answerInvitation', { accept: false })"
          >
            decline
            <v-icon right>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-container v-if="member.role === 'member' && member.status === 'active'">
        <v-row
          align="center"
          justify="start"
          class="px-3"
        >
          <v-btn 
            large
            rounded
            depressed
            :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
            @click="inviteOn = !inviteOn"
          >
            invite friends
          </v-btn>

          <div class="mx-2"></div>

          <v-btn 
            large
            rounded
            :outlined="$vuetify.theme.dark"
            depressed
            color="tertiary"
            @click="inviteOn = !inviteOn"
          >
            add to favorites
            <v-icon right>
              {{ mdiStarOutline }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-container v-if="!member._id">
        <v-row
          align="center"
          justify="start"
          class="px-3"
        >
          <v-btn 
            large
            rounded
            depressed
            :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
            @click="requestMembership"
            :loading="requestingMembership"
          >
            request membership
          </v-btn>
        </v-row>
      </v-container>
    </v-fade-transition>
  </div>
</template>

<script>
import InvitationDialog from '@components/Hall/InvitationDialog'
import NewRoom from '@components/Room/NewRoom'
import HallUpdater from '@components/Hall/Updater'
import Admin from './Admin.vue'
import ContentUploader from '@components/Content/Uploader';
import Member from '@api/member'
import { mapGetters } from 'vuex'

import {
  mdiCheck,
  mdiStarOutline,
  mdiClose,
  mdiUpload,
  mdiShieldCrownOutline,
  mdiPencil
} from '@mdi/js'

export default {
  components: {
    InvitationDialog,
    NewRoom,
    HallUpdater,
    ContentUploader,
    Admin
  },

  props: {
    community: {
      type: Object,
      default: () => ({})
    },
    room: {
      type: Object,
      default: () => ({})
    },
    member: {
      type: Object,
      default: () => ({})
    },
    members: {
      type: Object,
      default: () => ({})
    },
    reloadUserMember: {
      type: Function,
      default: () => {}
    }
  },

  data: () => ({
    mdiCheck,
    mdiStarOutline,
    mdiClose,
    mdiUpload,
    mdiShieldCrownOutline,
    mdiPencil,

    editHall: false,
    newRoomOn: false,
    inviteOn: false,
    uploadOn: false,
    adminOn: false,
    files: [],
    memberService: null,
    requestingMembership: false
  }),

  created() {
    this.memberService = Member(this.user.id)
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profileId: 'profile/id',
    }),
  },

  methods: {
    closeUpload() {
      this.uploadOn = false 
      this.files = []
    },
    async requestMembership() {
      this.requestingMembership = true
      try {
        const { notifications } = await this.memberService.requestCommunityMembership({
          community: this.$route.query.id,
          profile: this.profileId
        })
        notifications.forEach((notification) => {
          this.$socket.notification.emit('communityInvitation', {
            invitation: {
              ...notification
            },
            member: notification.profile,
            community: this.$route.query.id
          })
        })
      } catch (error) {
        console.log(error)
      }
      this.requestingMembership = false
      this.reloadUserMember()
    }
  }
}
</script>

<style scoped>
/* Round buttons */
.v-btn--rounded {
  border-radius: 15px; 
}
</style>